.firstUseContainer {
  padding: 30px;
}

.firstUseContainer h4 {
  margin-top: 30px;

}

.downloadPlatforms {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  row-gap: 10px;
  column-gap: 10px;
  margin-top: 30px;
}
.downloadPlatforms.twoLinesLayout {
  grid-template-columns: repeat(2, 1fr);
}

.downloadPlatforms .platform {
  text-align: center;
}
.downloadPlatforms .platform img {
  height: 60px;
}
.downloadPlatforms .platform h3 {
  margin-top: 10px;
  margin-bottom: 15px;
  color: rgb(10,10,10);
}

.downloadButton {
  margin-bottom: 10px !important;
}
.downloadButton span {
  display: block;
}

.downloadButton .packageInfo {
  font-size: 0.8em;
  font-weight: 300;
}

@media(max-width: 600px) {
  .downloadPlatforms {
    grid-template-columns: repeat(2, 1fr);
  }
}
