/* sora-200 - latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 200;
  src: local(''),
       url('res/fonts/sora-v1-latin-200.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('res/fonts/sora-v1-latin-200.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* sora-regular - latin */
@font-face {
  font-family: 'Sora';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('res/fonts/sora-v1-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('res/fonts/sora-v1-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local('Roboto Light'), local('Roboto-Light'),
       url('res/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('res/fonts/roboto-v20-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local('Roboto'), local('Roboto-Regular'),
       url('res/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('res/fonts/roboto-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local('Roboto Medium'), local('Roboto-Medium'),
       url('res/fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('res/fonts/roboto-v20-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  font-display: swap;
}

body {
  --source-sink-height: 100px;
}

.scene-grid {
  margin-top: 15px;
  display: grid;
  grid-template-columns: minmax(0, 1fr) 70px minmax(0, 1fr);
  row-gap: 30px;
}

.sources-title, .sinks-title {
  grid-row: 1;
  grid-column: 1;
  border-bottom: 1px solid rgba(10, 10, 10, 0.2);
  padding: 5px 5px 8px 5px;
}

.sinks-title {
  grid-column: 3;
}

.source-container, .sink-container {
  height: var(--source-sink-height);
  filter: drop-shadow(0 0.5em 1em rgba(10, 10, 10, 0.2));
  width: 100%;
  position: relative;
  transition: all 225ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  cursor: pointer;
  .handle {
    cursor: pointer;
    position: absolute;
    height: 20px;
    width: 20px;
    right: -20px;
    top: calc(50% - 10px);
    border-radius: 0 100% 100% 0;
    background-color: white;
    z-index: 1;
    &::after {
      content: '';
      background: #0060dd;
      width: 7px;
      height: 7px;
      position: absolute;
      border-radius: 100%;
      top: calc(50% - 3px);
      left: calc(50% - 3px);
    }
  }
  &.not-selectable {
    opacity: 0.3;
  }
}

.sink-container, .source-container {
  max-width: 100%;
}

.source-container {
  grid-column: 1;
}

.sink-container {
  grid-column: 3;
  .handle {
    left: -20px;
    right: auto;
    border-radius: 100% 0 0 100%;
  }
}

.source-box, .sink-box {
  overflow: hidden;
  position: relative;
  margin-bottom: 0 !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 100%;
  contain: layout style paint;
  .name {
    font-weight: bold;
  }
  p {
    z-index: 1;
    position: relative;
    text-overflow: ellipsis;
    max-width: 100%;
  }
  .peer-name {
    white-space: nowrap;
    font-size: 0.8rem;
    position: absolute;
    bottom: 0;
    right: 0;
    padding: 2px 5px;
    border-radius: 5px 0 0 0;
    background-color: rgba(10, 10, 10, 0.2);
    &.ownPeer {
      background-color: #ababff;
    }
  }
}

.unpipe-button {
  opacity: 0;
  position: absolute;
  transform: scale(0);
  transition: opacity 0.2s ease, transform 0.2s ease;
  background-color: rgba(10, 10, 10, 0.5);
  z-index: 2;
  top: 34px;
  left: -25px;
  &.active {
    opacity: 1;
    transform: scale(1);
  }
}

.source-logo, .sink-logo {
  position: absolute;
  left: -10px;
  top: -10px;
  pointer-events: none;
  opacity: 0.15;
  height: 80px;
  z-index: 0;
}

.sink-logo {
  left: auto;
  right: -13px;
  &.huelight {
    top: -5px;
  }
}

.pipe {
  pointer-events: none;
  grid-column: 2;
  position: relative;
  contain: layout style paint;
  z-index: 1;
  canvas {
    position: absolute;
    top: 0; bottom: 0;
    left: 0; right: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    animation: fadeIn 300ms ease;
  }
}

@keyframes fadeIn{
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@media (max-width: 769px) {
  html {
    font-size: 14px;
  }
  .source-box, .sink-box {
    border-radius: 0 6px 6px 0;
  }
  .sink-box {
    border-radius: 6px 0 0 6px;
  }
}

.soundsync-logo {
  width: 30px;
  height: 30px;
  margin-right: 10px;
}

.add-source, .add-sink {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  border: 1px dotted rgba(0,0,0,0.2);
  border-radius: 5px;
}
.add-source {
  grid-column: 1;
}
.add-sink {
  grid-column: 3;
}
.connecting-message {
  text-align: center;
  margin-top: 50px;
  p {
    max-width: 500px;
    margin: 20px auto 20px auto;
  }
}

@keyframes audioactivity {
  from {
    transform: scaleY(1);
  }
  25% {
    transform: scaleY(1.4);
  }
  50% {
    transform: scaleY(0.7);
  }
  to {
    transform: scaleY(1);
  }
}

.notsupported {
  display: flex;
  padding: 50px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
